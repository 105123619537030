<template>
  <div class="page-container find-pwd-form">
    <div class="page-header">
      <ace-header show-back>{{ $t('system.login.forgetFormTitle') }}</ace-header>
    </div>
    <div class="page-body">
      <van-form
        validate-first
        :show-error-message="false"
        validate-trigger="onSubmit"
        @submit="onSubmit"
        @failed="onFailed"
      >
        <div class="mx-4px">
          <van-field
            v-model="formData.password"
            :type="showPwd ? 'text' : 'password'"
            clearable
            maxlength="30"
            autocomplete="off"
            name="password"
            :placeholder="$t('system.login.password')"
            :rules="[{ pattern: pwdReg, message: $t('system.login.passwordValidateMsg') }]"
            class="mt-12px"
          >
            <template #right-icon>
              <van-icon
                :name="showPwd ? 'eye' : 'closed-eye'"
                size="20px"
                @click="showPwd = !showPwd"
              />
            </template>
          </van-field>
          <van-field
            v-model="formData.repeat_password"
            :type="showRepeatPwd ? 'text' : 'password'"
            clearable
            maxlength="30"
            autocomplete="off"
            name="repeat_password"
            :placeholder="$t('system.login.repeatPassword')"
            :rules="[{ validator: validateRepeatPwd }]"
            class="mt-12px"
          >
            <template #right-icon>
              <van-icon
                :name="showRepeatPwd ? 'eye' : 'closed-eye'"
                size="20px"
                @click="showRepeatPwd = !showRepeatPwd"
              />
            </template>
          </van-field>
          <div class="mt-30px mx-14px">
            <van-button
              block
              type="primary"
              :disabled="disabled"
              :loading="loading"
              native-type="submit"
            >
              {{ $t('common.okText') }}
            </van-button>
          </div>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { resetPassword } from '@/api/user';
  import { getParam } from '@/utils/assist';

  export default {
    name: 'ChangePwd',
    data() {
      return {
        pwdReg: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,30}$/,
        disabled: true,
        loading: false,
        formData: {
          password: '',
          repeat_password: '',
        },
        repeatPwdMsg: '',
        showPwd: false,
        showRepeatPwd: false,
      };
    },
    methods: {
      ...mapActions({
        setUserInfo: 'setUserInfo',
      }),
      validateRepeatPwd(value) {
        if (!this.pwdReg.test(value)) {
          this.repeatPwdMsg = this.$t('system.login.passwordValidateMsg');
          return false;
        }
        if (value !== this.formData.password) {
          this.repeatPwdMsg = this.$t('system.login.diffPwdValidateMsg');
          return false;
        }
        return true;
      },
      async onSubmit(values) {
        console.log(values);
        this.loading = true;
        try {
          const token = getParam('reset_token');
          const res = await resetPassword(token, values.password);
          if (res && res.user_token) {
            this.$notify({
              type: 'success',
              message: this.$t('system.login.resetPwdSucceed'),
            });
            const { email, user_token } = res;
            this.setUserInfo({
              userEmail: email,
              userToken: user_token,
            });
            const { redirect } = this.$route.query;
            if (redirect) {
              this.$router.replace({ name: redirect });
            } else {
              this.$router.replace({ name: 'home' });
            }
          }
        } finally {
          this.loading = false;
        }
      },
      onFailed({ errors }) {
        let msg = errors[0].message;
        if (errors[0].name === 'repeat_password') {
          msg = this.repeatPwdMsg;
        }
        this.$toast(msg);
      },
    },
    watch: {
      formData: {
        handler(data) {
          if (data.password && data.repeat_password) {
            this.disabled = false;
          } else {
            this.disabled = true;
          }
        },
        deep: true,
        immediate: true,
      },
    },
  };
</script>
